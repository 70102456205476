body {
    background-color: #fafafa !important;
    font-family: Helvetica, sans-serif !important;
    margin: 0;
    padding: 0;
    overflow-x: hidden !important;

}

.button-primary {
    border: 2px solid transparent;
    border-radius: 0px 25px 25px 25px !important;
    background-color: #CFDD33 !important;
    text-align: center !important;
    color: #155986 !important;
    font-family: Helvetica;
    font-weight: 900 !important;
    padding: 10px 15px !important;
    cursor: pointer !important;
}

.button-secondary {
    border: 2px solid #155986 !important;
    border-radius: 25px 25px 25px 25px !important;
    background-color: #fff !important;
    text-align: center !important;
    color: #155986 !important;
    font-family: Helvetica;
    font-weight: 900 !important;
    padding: 10px 15px !important;
    cursor: pointer !important;
    transition: all 0.3s ease-in;
}

.button-primary:hover,
.button-secondary:hover {
    background-color: #155986 !important;
    color: #fff !important;
}

input[type="file"] {
    display: none;
}

.custom-file-upload {

    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    font-size: 14px;

}

.toast-failed {
    background: #ff499e !important;
    color: #fff;
}

.toast-success {
    background: #18bebe !important;
    color: #fff;
}




.personal-image {
    text-align: center;
    display: flex;
    justify-content: center;
}

.personal-image input[type="file"] {
    display: none;
}

.personal-figure {
    position: relative;
    width: 80px;
    height: 80px;
}

.personal-avatar {
    cursor: pointer;
    width: 80px;
    height: 80px;
    box-sizing: border-box;
    border-radius: 100%;
    border: 2px solid transparent;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    transition: all ease-in-out .3s;
}

.personal-avatar:hover {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}

.personal-figcaption {
    cursor: pointer;
    position: absolute;
    top: 0px;
    width: inherit;
    height: inherit;
    border-radius: 100%;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0);
    transition: all ease-in-out .3s;
}

.personal-figcaption:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, .5);
}

.personal-figcaption>img {
    margin-top: 32.5px;
    width: 50px;
    height: 50px;
}


/* End of Personal Avatar */

.document-image {
    text-align: center;
    display: flex;
    justify-content: center;
    background-color: #eaeaea;
    border: 2px solid #eaeaea
}

.document-image input[type="file"] {
    display: none;
}

.document-figure {
    position: relative;
    width: 400px;
    height: 500px;
}

.document-avatar {
    cursor: pointer;
    width: 400px;
    height: 500px;
    box-sizing: border-box;
    /* border-radius: 100%; */
    border: 2px solid transparent;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    transition: all ease-in-out .3s;
}

.document-avatar:hover {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}

.document-figcaption {
    cursor: pointer;
    position: absolute;
    top: 0px;
    width: inherit;
    height: inherit;
    /* border-radius: 100%; */
    opacity: 0;
    background-color: rgba(0, 0, 0, 0);
    transition: all ease-in-out .3s;
}

.document-figcaption:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, .5);
}

.document-figcaption>img {
    margin-top: 32.5px;
    width: 50px;
    height: 50px;
}

.job-title:hover {
    color: #167dc1
}


* {
    box-sizing: border-box;
}

.container {
    max-width: 100%;
    /* Ensures no overflow */
}



/* @media only screen and (max-width: 768px) {
  
} */


/* For small devices like phones */
@media (max-width: 768px) {


    .container {
        width: 100%;
        /* Ensure containers adapt to screen width */
        padding: 0 10px;
        /* Add some padding for better readability */
        box-sizing: border-box;
    }

    .content {
        display: block;
        /* Stack elements vertically */
    }

    /* Adjust font sizes */
    h1,
    h2,
    h3,
    p {
        font-size: 1rem;
        /* Scale text for smaller screens */
    }

    .job-item {
        display: flex;
        flex-direction: column;
    }

    * {
        max-width: 100% !important;
        overflow-wrap: break-word;
        box-sizing: border-box;
    }

}